import { useIntl } from 'react-intl';
import React, { useState } from 'react';

import './CustomSearch.css';

const ENTER_KEY = 'Enter';

interface Props {
    searchValue: any;
    setSearchValue: any;
    handleInputChange: any;
    handleSearch: any;
    isSearchDisabled?: boolean;
}

export const CustomSearch = (props: Props) => {
    const { searchValue, setSearchValue, handleInputChange, handleSearch, isSearchDisabled } = props;

    const intl = useIntl();
    const [buttonOverRemoveIcon, setButtonOverRemoveIcon] = useState<boolean | undefined>(undefined);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === ENTER_KEY) {
            handleSearch();
        }
    };

    return (
        <div className={'display-flex justify-content-center'}>
            <div className={'col-xs-1'} />
            <div className={'col-xs-10 col-md-8 col-lg-6 col-xl-4'}>
                <div className={'input-group input-group-lg'}>
                    <span className={'input-group-addon'}>
                        <span className={'rioglyph rioglyph-search text-size-20'} />
                    </span>
                    <input
                        value={searchValue}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        className={'form-control'}
                        placeholder={intl.formatMessage({ id: 'helpgang.table.tableSearch' })}
                        data-cy={'input-account-search'}
                        readOnly={!!isSearchDisabled}
                    />
                    <span className={'input-group-addon'}>
                        {isSearchDisabled ? (
                            <span className={'btn btn-muted btn-loading btn-icon-only'} />
                        ) : (
                            <span
                                className={
                                    'rioglyph rioglyph-remove-sign text-size-20 margin-right--5' +
                                    (buttonOverRemoveIcon ? ' text-color-dark' : ' text-color-gray') +
                                    (searchValue === '' ? ' remove-icon-hidden' : ' remove-icon-shown')
                                }
                                onClick={() => setSearchValue('')}
                                aria-hidden={'true'}
                                style={{ cursor: searchValue ? 'pointer' : '' }}
                                onMouseEnter={() => setButtonOverRemoveIcon(true)}
                                onMouseLeave={() => setButtonOverRemoveIcon(false)}
                            />
                        )}
                    </span>
                </div>
            </div>
            <div className={'col-xs-1'}>
                <button className={'btn btn-primary btn-lg'} onClick={handleSearch}>
                    Search
                </button>
            </div>
        </div>
    );
};
